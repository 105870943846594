import styled from "styled-components";
import { colors, margins } from "../../styles/styles";

export const SeparatorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: calc(2*${margins.L});
    margin-top: ${margins.L};
`;

export const Line = styled.div`
    background-color: ${colors.secondary};
    display: flex;
    flex-grow: 2;
    height: 2px;
`;

export const SeparatorLogo = styled.img`
    margin: ${margins.L};
`;

export const SeparatorImage = styled.img`
    border-radius: ${margins.S};
    width: 100%;
    height: auto;
    margin-bottom: ${margins.XL};
`;