import React from 'react'
import PropTypes from 'prop-types'
import { SliderItemWrapper } from './SliderItem.styles'
import Testimonial from '../../components/testimonial/Testimonial'
import CourseTile from '../course-tile/CourseTile'

const SliderItem = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      posX1: 0,
      posX2: 0,
      posInitial: 0,
      threshold: 100
    }
  }

  dragStart = (e) => {
    e = e || window.event;
    e.preventDefault();
    let posX1;

    if (e.type === 'touchstart') {
      posX1 = e.touches[0].clientX;
    } else {
      posX1 = e.clientX;
      document.onmouseup = this.dragEnd;
      document.onmousemove = this.dragAction;
    }

    this.setState({ posX1: posX1 })
  }

  dragAction = (e) => {
    e = e || window.event;
    let posX1, posX2;

    if (e.type === 'touchmove') {
      posX2 = this.state.posX1 - e.touches[0].clientX;
      posX1 = e.touches[0].clientX;
    } else {
      posX2 = this.state.posX1 - e.clientX;
      posX1 = e.clientX;
    }
    this.state.sliderItem.style.left = (this.state.sliderItem.offsetLeft - posX2) + "px";

    this.setState({ posX1: posX1, posX2: posX2 });
  }

  dragEnd = (e) => {
    let posFinal = this.state.sliderItem.offsetLeft;
    if (posFinal - this.state.posInitial < -this.state.threshold) {
      this.props.onNextSlide();
    } else if (posFinal - this.state.posInitial > this.state.threshold) {
      this.props.onPreviousSlide();
    }

    this.state.sliderItem.style.left = (this.state.posInitial) + "px";

    document.onmouseup = null;
    document.onmousemove = null;
  }


  addTouchHandlers = () => {

    // Add touch events to all slide items
    let sliderItem = document.querySelector(`.slider-item-${this.props.slideIndex}`);
    this.setState({ sliderItem: sliderItem })

    // Mouse and Touch events
    sliderItem.onmousedown = this.dragStart;

    // Touch events
    sliderItem.addEventListener('touchstart', this.dragStart);
    sliderItem.addEventListener('touchend', this.dragEnd);
    sliderItem.addEventListener('touchmove', this.dragAction);
  }

  componentDidMount() {
    this.addTouchHandlers();
  }

  render() {
    return (
      <SliderItemWrapper className={`slider-item slider-item-${this.props.slideIndex} ${this.props.className}`}>
        {this.props.itemType === "Testimonial" && <Testimonial title={this.props.item.title} text={this.props.item.text} image={this.props.item.image} alt={`Koch ${this.props.item.title}`} />}
        {this.props.itemType === "CourseTile" && <CourseTile post={this.props.item.node} />}
      </SliderItemWrapper>
    )
  }
}

SliderItem.propTypes = {
  className: PropTypes.string,
  itemType: PropTypes.string,
  item: PropTypes.object,
  slideIndex: PropTypes.number,
}

export default SliderItem;
