import styled from "styled-components";
import { breakpoints, fonts, margins, paddings } from "../../styles/styles";

export const RoundedListWrapper = styled.div`
    margin-bottom: 140px;
    display: flex;
    grid-gap: ${margins.L};
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    @media(${breakpoints.M}) {
        flex-direction: unset;
    }
`;

export const RoundedListItem = styled.div`
    text-align: center;
    flex-grow: 2;

    .tile-image {
        border-radius: 100%;
        width: 200px;
        height: 200px;
        min-width: 200px;
        min-height: 200px;
        margin-bottom: ${margins.L};
        object-fit: cover;
    }
`;

export const RoundedListHeader = styled.h3`
    max-width: 250px;
    margin: ${paddings.S} auto;
`;

export const RoundedListSubheader = styled.h4`
    font-size: 18px !important;
    max-width: 250px;
    margin: auto;
`;

export const RounedListText = styled.p`
    font-family: ${fonts.regular};
    margin-top: ${margins.L};
    max-width: 250px;
    margin: ${paddings.S} auto;
`;