import React from 'react'
import { USPBox, USPBoxHeadline, USPBullets, USPBullet, USPWrapper, USPBulletImage } from './USP.styles'
import Title from '../../components/title/Title'
import PropTypes from 'prop-types'

class USP extends React.Component {

  render() {

    return (
      <>
        <Title title={this.props.USPTitle} centered={true} />
        <USPWrapper className={''} style={{ backgroundImage: `url(${this.props.USPImage ? this.props.USPImage.publicURL : ''})` }}>
          <div />
          <USPBox>
            <USPBoxHeadline>{this.props.USPBoxHeadline}</USPBoxHeadline>
            <USPBullets>
              {this.props.USPBoxBullets && this.props.USPBoxBullets.map((bullet, index) => {
                return <USPBullet key={index}>
                  <USPBulletImage />
                  <span>{bullet.text}</span>
                </USPBullet>;
              })}
            </USPBullets>
          </USPBox>
        </USPWrapper>
      </>
    )
  }
}

USP.propTypes = {
  className: PropTypes.string
}

export default USP