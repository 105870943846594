import styled from "styled-components";

export const SliderItemWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 1s;

  &.slide-hidden {
    opacity: 0;
    z-index: -1;
  }

  &.slide-visible {
    opacity: 1;
  }

  .testimonial {
    margin: 0 auto;
  }

`;