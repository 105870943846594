import React from 'react'
import PropTypes from 'prop-types'
import Button from '../button/Button'
import { getCourseUrl } from '../../utils/helpers'
import { SnipcartButtonWrapper } from './SnipcartButton.styles'
import { netlifyAuth } from '../../utils/auth'


const SnipcartButton = class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPurchaseButtonSticky: false,
      isCoursePaid: false
    }
  }

  componentDidMount() {

    // Add scroll event listener
    this.toggleSticky();

    // Get the latest paid courses information from API endpoint
    netlifyAuth.getIsCoursePaid(this.props.courseName).then(res => {
      this.setState({ isCoursePaid: res });
    });
  }

  toggleSticky = () => {

    // Only make sticky if threshold prop is passed
    if (!this.props.stickyThreshold) {
      return;
    }

    document.addEventListener('scroll', () => {
      const currentScrollY = window.scrollY;

      // Make purchase button sticky
      if (currentScrollY > this.props.stickyThreshold) {
        this.setState({ isPurchaseButtonSticky: true });
      } else {
        this.setState({ isPurchaseButtonSticky: false });
      }
    });
  };

  render() {
    return (
      <>
        {!this.state.isCoursePaid && <SnipcartButtonWrapper className={`${this.props.className || ''} ${this.state.isPurchaseButtonSticky ? 'sticky-purchase' : ''}`}>
          <div className="snipcart-add-item"
            data-item-id={this.props.productId}
            data-item-price={this.props.price}
            /*data-item-price={`{ "eur": ${price}, "chf": ${price * 1.1049} }`}*/
            data-item-url={getCourseUrl(this.props.pageId ? this.props.pageId.toString() : null)}
            data-item-name={this.props.productName}
            data-item-has-taxes-included='true'
            data-item-shippable='false'
            data-item-max-quantity={this.props.maxQuantity ? this.props.maxQuantity : '1'}
          >
            <Button text={this.props.text} smallText={this.props.smallText} icon={this.props.icon} filled={this.props.filled} />
          </div >
        </SnipcartButtonWrapper>
        }</>
    )
  }
}

SnipcartButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  price: PropTypes.number,
  productName: PropTypes.string,
  icon: PropTypes.string,
  isDisabled: PropTypes.bool,
  smallText: PropTypes.string,
  filled: PropTypes.bool,
  productId: PropTypes.string,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stickyThreshold: PropTypes.number
}

export default SnipcartButton
