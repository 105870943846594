import React from 'react'
import PropTypes from 'prop-types'

export const Title = ({ title, className, centered }) => (
  <h1 dangerouslySetInnerHTML={{ __html: title }} className={`${className || ''} ${centered ? 'text-center' : ''}`} />
)

Title.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  centered: PropTypes.bool
}

export default Title
