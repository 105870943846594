import React from 'react'
import PropTypes from 'prop-types'
import { StageWrapper, ContentWrapper, StageGradient, ContentInnerWrapper, StageImage } from './Stage.styles'
import Button from '../../components/button/Button'
import SnipcartButton from '../../components/snipcart-button/SnipcartButton'
import { netlifyAuth } from '../../utils/auth'
import { getImageUrl, isMobile } from '../../utils/helpers'
import WindowSizeListener from 'react-window-size-listener'
import StageVideo from '../../components/stage-video/StageVideo'

class Stage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isCoursePaid: false
    }
  }

  componentDidMount() {

    netlifyAuth.getIsCoursePaid(this.props.courseName).then(res => {
      this.setState({ isCoursePaid: res });
    });
    this.resizeBackgroundVideo();
  }

  resizeBackgroundVideo = () => {

    try {
      const stage = document.querySelector('.stage-wrapper');
      const video = document.querySelector('.stage-video');
      const image = document.querySelector('.stage-image');
      const gradient = document.querySelector('.stage-gradient');

      // Determine original video height, multiply it with Vimeo height factor
      let videoHeight = window.innerWidth * 0.527083;
      let stageHeight = stage.clientHeight;

      let heightScaleRatio = stageHeight / videoHeight;

      let padding = 80;
      if (window.innerWidth > 1597) {
        padding = 120;
      }
      let stageHeightRatio = ((stage.clientWidth - padding) * 0.5625) / window.innerHeight;

      // Apply stage size constraints to both video and background image
      video.style.width = `${Math.max(heightScaleRatio, 1) * 100}%`;

      video.style.maxHeight = `${stage.clientHeight}px`;
      video.style.minHeight = `${stageHeightRatio * 100}vh`;

      image.style.maxHeight = `${stage.clientHeight}px`;
      image.style.minHeight = `${stageHeightRatio * 100}vh`;

      gradient.style.maxHeight = `${stage.clientHeight}px`;
      gradient.style.minHeight = `${stageHeightRatio * 100}vh`;

      // Edge Case: it doesn't scale higher, need to adjust both width and height
      // Stop at some point, as video can't scale any higher
      if (window.innerWidth >= 1600) {
        let diffToFullWidth = 1 - heightScaleRatio; // missing % to 100%
        let newHeight = 1 + diffToFullWidth;
        video.style.width = '100%';
        video.style.height = `${newHeight * 100}%`;
        image.style.height = `${newHeight * 100}%`;
        gradient.style.height = `${newHeight * 100}%`;
      }
    } catch (e) {

    }
  }

  render() {

    return (
      <StageWrapper className={`${this.props.className} stage-wrapper`} style={{ backgroundImage: this.props.image ? `linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%), url(${getImageUrl(this.props.image)})` : '' }}>
        <ContentWrapper className={'content'}>
          {this.props.stageVideoSmall && this.props.stageVideoLarge && <>
            <StageImage className={'stage-image'} src={getImageUrl(this.props.image)} />
            <StageVideo videoURL={isMobile() ? `https://player.vimeo.com/video/${this.props.stageVideoSmall}` : `https://player.vimeo.com/video/${this.props.stageVideoLarge}`} />
            <WindowSizeListener onResize={(windowSize) => this.resizeBackgroundVideo(windowSize)} />
            <StageGradient className={'stage-gradient'} />
          </>}
          <ContentInnerWrapper>
            <div>
              <h1 className={'stage-title'} dangerouslySetInnerHTML={{ __html: this.props.title }} />
              {this.props.subtitle && <p className={'subtitle'} dangerouslySetInnerHTML={{ __html: this.props.subtitle }} />}
              {this.props.hideButtons !== true && <>
                {/* Landing Page: CTA to login/ signup when not logged in*/}
                {
                  !this.props.isCoursePage && <Button text={`Jetzt kaufen`} smallText={` - ${this.props.purchaseText}`} to={'#courses'} filled={true} stickyThreshold={500} />
                }
                {/* Course Page: CTA to buy course (to login or cart)*/}
                {
                  this.props.isCoursePage && this.props.courseOnline && this.props.hasPurchaseButton && !this.state.isCoursePaid &&
                  <SnipcartButton text={`Jetzt kaufen`} smallText={` - ${this.props.purchaseText}`} productName={this.props.courseName} price={this.props.coursePrice} productId={this.props.courseID} pageId={this.props.pageId} filled={true} stickyThreshold={500} />
                }
                {/* Course Page (Paid): CTA to watch video when logged in */}
                {
                  this.props.isCoursePage && this.props.courseOnline && this.props.hasPurchaseButton && this.state.isCoursePaid &&
                  <Button text={`Kochkurs starten`} productName={this.props.courseName} price={this.props.coursePrice} productId={this.props.courseID} to={`/course/course-${this.props.pageId.toString()}#video`} filled={true} stickyThreshold={500} />
                }
                {/* Course Page (Disabled): Coming soon */}
              </>}
              {
                this.props.isCoursePage && !this.props.courseOnline && this.props.hasPurchaseButton &&
                <Button text={`Coming Soon!`} disabled={true} />
              }
            </div>
            <div />
          </ContentInnerWrapper>
        </ContentWrapper>
      </StageWrapper>
    );
  };
}

Stage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasPurchaseButton: PropTypes.bool,
  coursePrice: PropTypes.number,
  courseName: PropTypes.string,
  courseID: PropTypes.string,
  purchaseText: PropTypes.string,
  exploreCourses: PropTypes.string,
  stageVideoSmall: PropTypes.string,
  stageVideoLarge: PropTypes.string,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  courseOnline: PropTypes.bool,
  isCoursePage: PropTypes.bool,
  hideButtons: PropTypes.bool,
}

export default Stage
