import styled from "styled-components";
import { breakpoints, colors, paddings } from '../../styles/styles';

export const SliderWrapper = styled.div`
  position: relative;
  display: block;
  height: 500px;
  width: 100%;
`;

export const SliderButton = styled.div`
  min-width: 80px;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translate(0%,-50%);
  display: none;

  @media(${breakpoints.M}) {
    display: flex;
  }

  svg {
    transition: 0.4s width, 0.4s height;
    width: 80px;
    height: 80px;
  }

  &:hover {
    svg {
      width: 90px;
      height: 90px;
    }
  }

  &.left {
    left: 24px;

    @media(${breakpoints.s}) {
      left: 72px;
    }
  }

  &.right {
    right: 24px;

    @media(${breakpoints.s}) {
      right: 72px;
    }
  }
`;

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const Step = styled.div`

  cursor: pointer;
  padding: ${paddings.S};

  & .inner {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: ${colors.secondary};
  }

  &.active .inner {
    background-color: ${colors.primary};
  }
`;
