import styled from "styled-components";

export const SnipcartButtonWrapper = styled.div`

    &.sticky-purchase {
        position: fixed;
        bottom: 50px;
        left: 0px;
        right: 0px;
        z-index: 8;
        display: flex;
        justify-content: center;
        margin: auto;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3));
    }
`;