import React from 'react'
import PropTypes from 'prop-types'
import { StageVideoWrapper } from './StageVideo.styles'

const StageVideo = class extends React.Component {

  componentDidMount() {
    let videoURL = this.props.videoURL + '?background=1'
    document.querySelector('#stage-video').src = videoURL;
  }

  render() {
    return (
      <StageVideoWrapper className={`stage-video ${this.props.className || ''}`}>
        {this.props.videoURL && <>
          <iframe id={'stage-video'} src={'about:blank'} title={'Vorschau-Video'} />
        </>
        }
      </StageVideoWrapper>
    );
  }
};

StageVideo.propTypes = {
  className: PropTypes.string,
  videoURL: PropTypes.string.isRequired,
  id: PropTypes.string
}

export default StageVideo;
