import styled from "styled-components";
import { breakpoints, fonts, margins } from "../../styles/styles";

export const StageWrapper = styled.div`
    display: flex;
    height: auto;
    padding-bottom: ${margins.XL};
    position: relative;
    overflow: hidden;
    background-size: cover;

    h1 {
        margin-bottom: 24px;
        margin-top: 220px;
        color: white;
        text-transform: uppercase;
        font-size: 48px;
        line-height: 100%;
        font-family: ${fonts.bold};
        position: relative;      
        padding-bottom: 0px;  

        @media(${breakpoints.M}) {
            font-size: 64px;
        }

        @media(${breakpoints.L}) {
            font-size: 72px;
        }
    }

    .subtitle {
        color: white;
        position: relative;
        margin-bottom: 32px !important;
    }

    button {
        position: relative;
    }
`;

export const ContentInnerWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
    @media(${breakpoints.M}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const ExploreCourses = styled.a`
    display: flex;
    color: white;
    margin: auto;
    margin-top: 70px;
    justify-content: center;
    position: relative;

    span {
        margin-right: ${margins.M}; 
        color: white;
    }

    @media(${breakpoints.M}) {
        visibility: hidden;
    }
`;

export const ContentWrapper = styled.div`
    padding-top: 160px;
    align-items: center;
`;

export const StageGradient = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
`;

export const StageImage = styled.img`
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    left: 50%;
    transform: translate(-50%, 0);
    object-fit: cover;
`;