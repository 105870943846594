import styled from "styled-components";
import { breakpoints, colors, margins } from "../../styles/styles";

export const VoucherWrapper = styled.div`
    background-color: ${colors.secondary};
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    padding: 60px;

    h1, p {
        color: white;
    }

    button {
        margin-bottom: ${margins.M};
    }
`;

export const VoucherImage = styled.div`
    max-width: 500px;
    height: auto;
    margin-top: ${margins.L};
    margin-bottom: ${margins.M};
`;

export const PersonImage = styled.div`
    --person-image-width: 202px;
    margin-top: calc((-1* var(--person-image-width) / 2) - 60px);
    width: var(--person-image-width);
    height: var(--person-image-width);
    margin-bottom: ${margins.L};
`;

export const VoucherButtons = styled.div`

    @media(${breakpoints.M}) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${margins.L};
    }
`;