import styled from "styled-components";
import { breakpoints, fonts, margins, paddings } from "../../styles/styles";

export const USPWrapper = styled.div`
    border-radius: ${margins.S};
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: ${margins.L};
    background-color: white;
    background-size: cover;
    margin-bottom: 140px;

    @media(${breakpoints.M}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const USPBox = styled.div`
    border-radius: ${margins.S};
    background-color: white;
    margin: ${margins.M};
    margin-top: 270px;
    padding: ${paddings.L};

    @media(${breakpoints.M}) {
        margin: ${margins.L};
        margin-top: 350px;
    }
`;

export const USPBoxHeadline = styled.div`
    font-family: ${fonts.bold};
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 24px;
    text-transform: uppercase;
`;

export const USPBulletImage = styled.div`
    min-width: 6px;
    max-height: 6px;
    border-radius: 100%;
    background-color: black;
    margin-right: 10px;
    margin-left: 15px;
    margin-top: 10px;
`;

export const USPBullets = styled.div`
    margin-top: ${margins.L} !important;
`;

export const USPBullet = styled.div`
    color: black;
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: ${margins.M};
    display: flex;
    align-items: top;

    & img {
        height: 24px;
        width: 24px;
        margin-right: ${margins.S};
    }
`;

