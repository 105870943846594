import styled from "styled-components";

export const NewsletterWrapper = styled.div`
    display: flex;
    max-width: 380px;
    margin: auto;
    text-align: center;
    justify-content: center;
    padding-bottom: 138px;

    iframe {
        margin-top: -50px;
        margin-bottom: -80px;
    }
`;

export const SmallText = styled.p`
    font-size: 12px !important;
    line-height: 16px !important;
    z-index: 10;

    a {
        text-decoration: underline;
    }
`;