import React from 'react'
import PropTypes from 'prop-types'
import { SeparatorWrapper, Line, SeparatorLogo, SeparatorImage } from './Separator.styles'
import SeparatorLineImage from './../../img/separator-2.svg'
import { getImageUrl } from '../../utils/helpers'

export const Separator = ({ className, image }) => (
  <SeparatorWrapper className={`${className}`}>
    {image && <SeparatorImage src={getImageUrl(image)} />}
    {!image && <>
      <Line />
      <SeparatorLogo src={SeparatorLineImage} />
      <Line />
    </>}
  </SeparatorWrapper>
)

Separator.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
}

export default Separator
