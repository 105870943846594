import React from 'react'
import PropTypes from 'prop-types'
import { PaymentsWrappper } from './Payments.styles'

import Payment_PayPal from '../../img/paypal.svg';
import Payment_Visa from '../../img/visa.svg';
import Payment_Mastercard from '../../img/mastercard.png';
import LogoWall from '../publicity/LogoWall';

export const paymentLogos = [
  { source: Payment_PayPal, alt: 'PayPal' },
  { source: Payment_Visa, alt: 'Visa' },
  { source: Payment_Mastercard, alt: 'Mastercard' }
];

export const Payments = ({ className }) => (
  <PaymentsWrappper className={`${className} payments`}>
    <LogoWall className={'payments'} title={'Einfach und sicher <br/>bezahlen:'} logos={paymentLogos} />
  </PaymentsWrappper>
)

Payments.propTypes = {
  className: PropTypes.string,
}

export default Payments
