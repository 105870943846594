import styled from "styled-components";
import { margins, paddings } from "../../styles/styles";

export const TestimonialWrapper = styled.div`
    max-width: 629px;
`;

export const ImageWrapper = styled.div`

    img {
        width: 100%;
        border-radius: 8px;
        max-height: 430px;
        object-fit: cover;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    background-color: white;
    flex-flow: column;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: ${margins.M};
    margin-top: -64px;
    position: relative;
    padding: ${paddings.L};
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

export const Text = styled.p`
    font-size: 16px;
`;

export const Author = styled.p`
    margin-top: ${margins.M};
    font-size: 16px;
`;

export const TestimonialTitle = styled.h3`
`;