import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Button from '../../components/button/Button'
import { CourseTileWrapper, CourseTitle, ButtonWrapper, BottomAlign, CookText, CourseTileContentWrapper, CourseDescription } from './CourseTile.styles'
import { trimText, getImageUrl } from '../../utils/helpers'


export const CourseTile = ({ post, className }) => (

  <CourseTileWrapper className={`${className || ''} course-tile-wrapper`} key={post.id}>
    <CourseTileContentWrapper style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%), url(${getImageUrl(post.frontmatter.image)})` }}>
      <BottomAlign />
      <div className="post-meta">
        <CourseTitle>
          {
            post.frontmatter.courseOnline && <Link to={post.fields.slug}>
              {post.frontmatter.frontend_title}
            </Link>
          }
          {
            !post.frontmatter.courseOnline && <span>{post.frontmatter.frontend_title}</span>
          }
        </CourseTitle>
        <CookText>mit {post.frontmatter.cook.name}</CookText>
        <CourseDescription>{trimText(post.frontmatter.description, 90)}</CourseDescription>
      </div>
      <ButtonWrapper>
        {
          post.frontmatter.courseOnline && <>
            <Button to={post.fields.slug} text={'Zum Kurs'} className={'primary'} />
            <Button to={post.fields.slug + '#video'} text={'Trailer ansehen'} />
          </>
        }
        {
          !post.frontmatter.courseOnline && <Button text={'Coming Soon!'} disabled={true} />
        }
      </ButtonWrapper>
    </CourseTileContentWrapper>
  </CourseTileWrapper >
);

CourseTile.propTypes = {
  post: PropTypes.object
}

export default CourseTile;
