import React from 'react'
import PropTypes from 'prop-types'
import { LogoWallWrapper, LogoWrapper, Logo } from './LogoWall.styles'
import Title from '../../components/title/Title'
import { getImageUrl } from '../../utils/helpers'

export const LogoWall = ({ className, title, logos }) => (
  <LogoWallWrapper className={`${className} margin-bottom`}>
    <Title title={title} centered={true} />
    {
      logos && <LogoWrapper>
        {logos.map((logo, index) => {
          return <Logo key={index}><img className={'lazyload'} data-src={getImageUrl(logo)} alt={'logo'} key={index} /></Logo>
        })}
      </LogoWrapper>
    }
  </LogoWallWrapper>
)

LogoWall.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(PropTypes.object)
}

export default LogoWall
