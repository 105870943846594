import styled from "styled-components";
import { breakpoints, colors, fonts, margins, paddings } from "../../styles/styles";

export const CourseTitle = styled.h2`
    margin-bottom: 0px !important;
    text-transform: uppercase;

    a, span {
        color: white !important;
        font-family: ${fonts.bold};
        font-weight: 900;
        letter-spacing: 0;
        line-height: 100% !important;
        font-size: 30px;
        line-height: 78%;
        letter-spacing: -0.02em;

        @media(${breakpoints.M}) {
            font-size: 43px;
            line-height: 78%;
        }

        &:hover {
            color: white !important;
        }
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: ${margins.L};
    flex-direction: column;
    align-items: baseline;

    @media(${breakpoints.M}) {
        flex-direction: row;
    }

    a {
        font-size: 16px !important;
    }
`;

export const CourseTileWrapper = styled.div`
    margin: auto;
    width: 100%;
`;

export const CourseTileContentWrapper = styled.div`
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    background-color: ${colors.secondary};
    padding: ${paddings.L};
    height: 453px;
    display: flex;
    flex-flow: column;
    background-position: center;
    background-size: cover;
`;

export const BottomAlign = styled.div`
    flex-grow: 1;
`;

export const CookText = styled.p`
    margin-bottom: ${margins.L} !important;
    color: white !important;
`;

export const CourseDescription = styled.p`
    color: white !important;
    line-height: 150% !important;
    font-size: 18px !important;
`;

export const CourseTilesHeader = styled.div`
     
    h1 {
        @media(${breakpoints.L}) {
            margin: unset;
            text-align: left !important;
            max-width: 397px;
        }
    }
`;