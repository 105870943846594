import React from 'react'
import PropTypes from 'prop-types'
import { SliderWrapper, SliderButton, StepsWrapper, Step } from './Slider.styles'
import { ReactSVG } from 'react-svg'
import PreviousSVG from './../../img/previous.svg'
import NextSVG from './../../img/next.svg'
import SliderItem from '../sliderItem/SliderItem'

const Slider = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentSlide: 0
    }
  }

  nextSlide = () => {
    let _nextSlide = this.state.currentSlide + 1;
    if (_nextSlide >= this.props.items.length) {
      this.setState({ currentSlide: 0 });
    } else {
      this.setState({ currentSlide: _nextSlide });
    }
  };

  previousSlide = () => {
    let _previousSlide = this.state.currentSlide - 1;

    if (_previousSlide === -1) {
      this.setState({ currentSlide: this.props.items.length - 1 });
    } else {
      this.setState({ currentSlide: _previousSlide });
    }
  };

  changeSlide = (_currentSlide) => {
    this.setState({ currentSlide: _currentSlide });
  };

  componentDidMount() {

    if (this.props.autoSlide) {
      const _timer = setInterval(() => {

        // Loop through slide, reset at the end of it
        if (this.state.currentSlide === this.props.items.length - 1) {
          this.changeSlide(0);
        } else {
          this.nextSlide();
        }
      }, 10000);

      return () => clearInterval(_timer);
    }
  }

  render() {
    return (
      <>
        <SliderWrapper className={`slider background-${this.props.backgroundColor || ''} ${this.props.className || ''}`}>
          <SliderButton onClick={this.previousSlide} className={'left'}>
            <ReactSVG src={PreviousSVG} />
          </SliderButton>
          {this.props.items.map((item, index) => {
            return <SliderItem itemType={this.props.itemType} post={item.node} item={item} className={`${index === this.state.currentSlide ? 'slide-visible' : 'slide-hidden'}`} key={index} onNextSlide={this.nextSlide} onPreviousSlide={this.previousSlide} slideIndex={index} />;
          })}
          <SliderButton onClick={this.nextSlide} className={'right'}>
            <ReactSVG src={NextSVG} />
          </SliderButton>
        </SliderWrapper >
        <StepsWrapper>
          {this.props.items.map((item, index) => {
            return <Step key={index} className={`${this.state.currentSlide === index ? 'active' : ''}`} onClick={() => this.changeSlide(index)}>
              <div className={'inner'} />
            </Step>
          })}
        </StepsWrapper>
      </>
    )
  }
}

Slider.propTypes = {
  className: PropTypes.string,
  itemType: PropTypes.string,
  autoSlide: PropTypes.bool
}

export default Slider;
