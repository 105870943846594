import React from 'react'
import PropTypes from 'prop-types'
import { NewsletterWrapper, SmallText } from './Newsletter.styles'
import Title from '../../components/title/Title'

const Newsletter = class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      recaptchaLoaded: false
    }
  }

  render() {
    return (
      <NewsletterWrapper className={`${this.props.className} columns`}>

        <Title title={'Newsletter'} centered={true} />
        <span>Jetzt unseren Newsletter abonnieren und nichts mehr verpassen!</span>
        <iframe width="540" height="305" title={'Newsletter'} src="https://53dfa250.sibforms.com/serve/MUIEAHhsCAcS3pD7TfCa6NVfopWk1YqRxgkv0-mdYz3ReQZcjWTcJCe6cVdr-o1C7xFmiJF1WmbBLdmBd8NdSe35Ja-9WpCBmN3A5paF28PMwlFkVBPRhk4SuWoPLCmCbLjFvN_RdqhZERGZfGF4WOTDy1desAYnCdz9qqkRSmmTku-tc5f4xtIND3WjDgHbXkCAb2vluCCxviyU" frameBorder="0" scrolling="auto" allowFullScreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }} id={'newsletter-widget'}></iframe>

        <SmallText>Abmeldung jederzeit möglich. Weitere Informationen dazu finde ich in der <a href={'/privacy'}>Datenschutzerklärung.</a></SmallText>


      </NewsletterWrapper >
    )
  }
}

Newsletter.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string
  }))
}

export default Newsletter
