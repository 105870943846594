import React from 'react'
import RoundedList from '../rounded-list/RoundedList'
import { TestimonialsWrapper } from './Testimonials.styles';

class Testimonials extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <TestimonialsWrapper>
        <RoundedList items={this.props.testimonials} />
      </TestimonialsWrapper>
    )
  }
}

export default Testimonials;
