import React from 'react'
import PropTypes from 'prop-types'
import { RoundedListHeader, RoundedListWrapper, RoundedListItem, RounedListText, RoundedListSubheader } from './RoundedList.styles'
import Title from '../../components/title/Title'
import { getImageUrl } from '../../utils/helpers'


class RoundedList extends React.Component {
  render() {

    return (
      <>
        {this.props.title && <Title title={this.props.title} />}
        {this.props.items && <RoundedListWrapper className={`rounded-list ${this.props.className || ''} ${this.props.fullWidth ? 'full-width' : ''}`}>
          {this.props.items &&
            this.props.items.map((item, index) => {
              return <RoundedListItem key={index}>
                <img className={'lazyload tile-image'} data-src={getImageUrl(item.image)} />
                {item.title && <RoundedListHeader>{item.title}</RoundedListHeader>}
                {item.subtitle && <RoundedListSubheader>{item.subtitle}</RoundedListSubheader>}
                <RounedListText>{item.text}</RounedListText>
              </RoundedListItem>;
            })}
        </RoundedListWrapper>}
      </>
    )
  }
}

RoundedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    subtitle: PropTypes.string
  })),
  className: PropTypes.string,
  title: PropTypes.string,
}

export default RoundedList
