import React from 'react'
import PropTypes from 'prop-types'
import { TestimonialWrapper, ImageWrapper, ContentWrapper, Text, TitleWrapper, TestimonialTitle } from './Testimonial.styles'
import { getImageUrl } from '../../utils/helpers'

export const Testimonial = ({ className, image, title, text }) => (
  <TestimonialWrapper className={`column is-12-mobile testimonial ${className}`}>
    <ImageWrapper>
      <img className={'lazyload'} data-src={getImageUrl(image)} />
    </ImageWrapper>
    <ContentWrapper>
      <TitleWrapper>
        <TestimonialTitle>{title}</TestimonialTitle>
      </TitleWrapper>
      <Text>{text}</Text>
    </ContentWrapper>
  </TestimonialWrapper>
)

Testimonial.propTypes = {
  className: PropTypes.string,
  testimonial: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.string
  }))
}

export default Testimonial
