import React from 'react'
import PropTypes from 'prop-types'
import { PersonImage, VoucherImage, VoucherWrapper, VoucherButtons } from './Voucher.styles'
import SnipcartButton from '../../components/snipcart-button/SnipcartButton'
import { getImageUrl, getLocalePrice } from '../../utils/helpers'
import { graphql, StaticQuery } from 'gatsby'
import VoucherImg from '../../img/voucher.png'
import Title from '../../components/title/Title'

class Voucher extends React.Component {

  render() {

    let data = {}
    if (this.props.data && this.props.data.markdownRemark && this.props.data.markdownRemark.frontmatter) {
      data = this.props.data.markdownRemark.frontmatter;
    }

    return (

      <VoucherWrapper className={`margin-bottom`} id={'voucher'}>
        <PersonImage>
          <img className={'lazyload'} data-src={getImageUrl(data.voucherImage)} alt={'Chefkoch'} />
        </PersonImage>
        <Title title={data.voucherTitle} />
        <p>{data.voucherText}</p>
        <VoucherImage>
          <img className={'lazyload'} data-src={VoucherImg} alt={'Gutschein'} />
        </VoucherImage>
        <VoucherButtons>
          {data.voucherPrices && data.voucherPrices.map((voucher, index) => {
            return <SnipcartButton key={index} price={voucher.price} productId={`voucher-${voucher.price}`} text={`Gutschein über ${getLocalePrice(voucher.price, true)}`} productName={`Kursgutschein ${getLocalePrice(voucher.price, true)}`} maxQuantity={100} filled={true} />
          })}
        </VoucherButtons>
      </VoucherWrapper>
    )
  }
}

Voucher.propTypes = {
  className: PropTypes.string
}

export default () => (
  <StaticQuery
    query={graphql`
    query VoucherQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
            voucherTitle
            voucherText
            voucherPrices {
              price
            }
            voucherImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Voucher data={data} />}
  />
)
