import styled from "styled-components";
import { colors } from "../../styles/styles";

export const StageVideoWrapper = styled.div`
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    left: 50%;
    transform: translate(-50%, 0);

    iframe {
        width: 100%; 
        height: 100%;
        background-color: ${colors.secondary};
    }
`;