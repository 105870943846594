import React from 'react'
import PropTypes from 'prop-types'
import RoundedList from '../rounded-list/RoundedList'
import { BenefitsWrapper } from './Benefits.styles'

class Benefits extends React.Component {

  render() {

    return (
      <BenefitsWrapper>
        <RoundedList items={this.props.benefits} title={this.props.benefitsTitle} />
      </BenefitsWrapper>
    )
  }
}

Benefits.propTypes = {
  className: PropTypes.string
}

export default Benefits
